import {
  type DASHProvider,
  isDASHProvider,
  type MediaProviderChangeEvent,
} from "vidstack";
import type { MediaPlayerElement } from "vidstack/elements";
import { VidstackPlayer, VidstackPlayerLayout } from "vidstack/global/player";
import "vidstack/player/styles/default/layouts/audio.css";
import "vidstack/player/styles/default/layouts/video.css";
import "vidstack/player/styles/default/theme.css";
import "./style.css";

function onHashChange(player: MediaPlayerElement) {
  const hashParams = new URLSearchParams(window.location.hash.substring(1));
  console.debug("Applying hash params", hashParams);

  player.src = hashParams.getAll("src");
  player.title = hashParams.get("title") || "";
  player.poster = hashParams.get("poster") || "";
  // resets time to 0 on every param change otherwise
  const ct = parseFloat(hashParams.get("t") || "NaN");
  if (!isNaN(ct)) player.currentTime = ct;
  player.clipStartTime = parseFloat(hashParams.get("start") || "0") || 0;
  player.clipEndTime = parseFloat(hashParams.get("end") || "0") || 0;
  // @ts-ignore
  player.streamType = hashParams.get("streamType") || "unknown";
  // @ts-ignore
  player.viewType = hashParams.get("viewType") || "unknown";
}

function onProviderChange(e: MediaProviderChangeEvent) {
  if (isDASHProvider(e.detail)) {
    const dashProvider = e.detail as DASHProvider;
    dashProvider.library = () => import("dashjs");
  }
}

VidstackPlayer.create({
  target: "#target",
  preferNativeHLS: true,
  storage: "meow",
  layout: new VidstackPlayerLayout({
    download: true,
  }),
}).then((player) => {
  console.debug("Player initialized", player);
  player.addEventListener("provider-change", onProviderChange);
  onHashChange(player);
  window.addEventListener("hashchange", () => onHashChange(player));
  Object.defineProperty(window, "player", { value: player });
});
